<script setup lang="ts">
import type { CmsElementForm } from "@shopware-pwa/composables-next";
import { useCmsElementConfig } from "#imports";
import { computed, defineAsyncComponent } from "vue";

const props = defineProps<{
  content: CmsElementForm;
}>();

const { getConfigValue } = useCmsElementConfig(props.content);

const FormComponent = computed(() => {
  switch (getConfigValue("type")) {
    case "newsletter":
      return defineAsyncComponent(
        () => import("../../Sw/SwNewsletterForm"),
      );
    case "contact":
    default:
      return defineAsyncComponent(() => import("../../Sw/SwContactForm"));
  }
});
</script>
<template>
  <div class="cms-element-form">
    <component :content="content" :is="FormComponent" />
  </div>
</template>
